/* eslint-disable no-console */
export default function parseCookie(): { [key: string]: string } {
  return document.cookie.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent);
    const allNumbers = (str: string) => /^\d+$/.test(str);
    try {
      return Object.assign(res, { [key]: allNumbers(val) ? val : JSON.parse(val) });
    } catch (error) {
      console.error('Error parsing cookie', error);
      return Object.assign(res, { [key]: val });
    }
  }, {});
}
